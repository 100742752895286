<template>
  <div class="container">
    <vue-canvas-poster :widthPixels="750" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
  </div>
</template>

<script>
  import {
    VueCanvasPoster
  } from 'vue-canvas-poster'
  export default {
    // 允许组件模板递归地调用自身
    name: 'my-vue-canvas-poster',
    // 声明一组可用于组件实例中的组件
    components: {
      VueCanvasPoster
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      options: {
        type: Object,
        default: () => {
          let result = {
            url: ''
          }
          return result;
        }
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        mapOptions: this.options,
        painting: {},
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      options: function (e) {
        console.log('options e == ', e)
        this.mapOptions = e;
        // 初始化-画布数据
        this.initCanvarDataFun();

        console.log('this.mapOptions == ', this.mapOptions);
      },
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化-画布数据
       */
      initCanvarDataFun() {
        let url = this.mapOptions.url;
        // // 获取图片大小
        // this.myGetImgSize(url).then((res) => {
        //   console.log('初始化-画布数据 获取图片大小 res == ', res);
        //   let imgInfo = res;
        // 图片信息
        let imgInfo = {
          width: 370,
          height: 370,
        };

        // 画布大小
        let canvar = {
          width: 550,
          height: 550,
          rowGutter: 25,
          colGutter: 25,
        }

        // 矢量图-二维码
        let vectorImg = {
          width: (imgInfo.width * (canvar.width - (canvar.colGutter * 2)) / imgInfo.width),
          height: (imgInfo.height * (canvar.width - (canvar.rowGutter * 2)) / imgInfo.width),
        }

        console.log('获取图片大小 vectorImg == ', vectorImg);

        // 总高度
        let totalHeight = ((canvar.rowGutter * 2) + vectorImg.height) + (canvar.rowGutter * 2);

        let painting = {
          width: canvar.width + 'px',
          height: totalHeight + 'px',
          background: '#ffffff',
          views: [{
            type: 'image',
            url: url,
            css: {
              top: canvar.rowGutter + 'px',
              left: canvar.colGutter + 'px',
              width: vectorImg.width + 'px',
              height: vectorImg.height + 'px',
            },
          }, {
            type: 'text',
            text: '推广二维码',
            css: [{
              width: '100%',
              top: vectorImg.height + canvar.rowGutter + 'px',
              textDecoration: '推广二维码',
              fontSize: '40px',
              textAlign: 'center'
            }]
          }, ],
        };

        this.painting = painting;
        // })
      },
      success(src) {
        // console.log('success == ', src)
        // 告知弹窗变化
        this.informChangeFun({
          visible: false,
          status: 1, // 1/成功 2/取消 3/其他
          message: '成功',
          data: src,
        });
      },
      fail(err) {
        console.log('fail == ', err)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 2, // 1/成功 2/取消 3/其他
          message: '取消',
          data: '',
          event: 'on-change'
        };
        let result = Object.assign(default_data, opt);
        this.$emit(default_data.event, result)
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

</style>
