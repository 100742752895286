<template>
  <div class="container">
    <div class="account-admin-module">
      <div class="aam-main">
        <div class="create-qrcode aam-module">
          <el-button class="fake-btn" @click="openCreateQrcodeWinFun">
            <div class="icon">
              <!-- <div class="icon-box">
                <i class="iconfont icon-weixinzhifu"></i>
              </div> -->
              <span class="icon-text">点击生成推广二维码</span>
            </div>
          </el-button>
        </div>
        <div class="wallet-module">
          <div class="wm-my-wallet aam-module">
            <div class="wmw-title">我的钱包</div>
            <div class="wmw-rest">
              <div class="wmw-deposit">
                <div class="wd-item">
                  账户余额：<span
                    class="big-size">{{myWalletInfo.loading ? '￥' + myWalletInfo.resData.normal_money : '--'}}</span>
                </div>
                <div class="wd-item small-hand" @click.capture="clickWithdrawDepositBtnFun">提现</div>
              </div>
            </div>
            <div class="wmw-hide" @click="myWalletInfo.visible = !myWalletInfo.visible">
              [{{myWalletInfo.visible ? '隐藏' : '显示'}}]</div>
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="myWalletInfo.visible" class="financial-report">
              <ul class="fr-list">
                <template v-for="(item,index) in myWalletInfo.fr_list ">
                  <li class="fl-item aam-module" :key="index">
                    <h3 class="fl-title">{{item.name}}</h3>
                    <div class="fl-price">
                      <span class="price-unit">￥</span>
                      <span class="price-text">{{item.price}}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </transition>
        </div>
        <div class="promote-module">
          <div class="pm-main aam-module">
            <div class="pm-header">
              <div class="form-module">
                <div class="fm-tr">
                  <div class="fm-itme">
                    <!-- <div class="fm-key">
                      <div class="key-title required-style">出单时间：</div>
                    </div> -->
                    <div class="fm-val">
                      <div class="picker-date">
                        <el-date-picker v-model="start_end_time" type="datetimerange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" placeholder="请选择您活动的开始时间"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="function(e) { if(e && e.length >= 2 ) {returnsListObject.formData.pay_start_time = e[0]; returnsListObject.formData.pay_end_time = e[1];} else {returnsListObject.formData.pay_start_time = ''; returnsListObject.formData.pay_end_time = '';}}">

                        </el-date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="sm-control">
                <li class="control-item serach-btn">
                  <el-button class="fake-btn" @click="clickSerachReturnsListFun">
                    <span>立即查询</span>
                  </el-button>
                </li>
                <!-- <li class="control-item reset-btn" @click="clickResetAccountFun">
                  <el-button class="fake-btn">
                    <span>重置</span>
                  </el-button>
                </li> -->
              </ul>
            </div>
            <div class="pm-header">
              <ul class="sm-control">
                <li class="control-item" :class="returnsListObject.formData.type == 1 ? 'active' : ''">
                  <el-button class="fake-btn" @click="function(e) {  return returnsListTypeChangeFun(e, 1) }">
                    <span>消费收益</span>
                  </el-button>
                </li>
                <li class="control-item" :class="returnsListObject.formData.type == 2 ? 'active' : ''">
                  <el-button class="fake-btn" @click="function(e) {  return returnsListTypeChangeFun(e, 2) }">
                    <span>推广收益</span>
                  </el-button>
                </li>
                <!-- <li class="control-item reset-btn" @click="clickResetAccountFun">
                  <el-button class="fake-btn">
                    <span>重置</span>
                  </el-button>
                </li> -->
              </ul>
            </div>
            <div class="pm-body">
              <div class="table-place">
                <el-table border row-class-name="fake-table-tr" :data="returnsListObject.tableData.list"
                  style="width: 100%">
                  <!-- <el-table-column
                    label-class-name="fake-table-th"
                    width="100px"
                    align="center"
                    prop="name"
                    label="序号">
                      <template slot-scope="scope">
                        <span v-if="(scope.$index + 1) < 10">00{{scope.$index + 1}} </span>
                        <span v-else-if="(scope.$index + 1) >= 10 || (scope.$index + 1) < 100">0{{scope.$index +1}} </span>
                        <span v-else>{{scope.$index + 1 }} </span>
                      </template>
                  </el-table-column> -->
                  <el-table-column label-class-name="fake-table-th" align="center" prop="user_info" label="购买用户">
                    <template slot-scope="scope">
                      <div class="user-info">
                        <div class="user-image">
                          <el-image class="fake-img" :src="scope.row.user_info.avatar" fit="scale-down"></el-image>
                        </div>
                        <div class="user-name">{{scope.row.user_info.nickname}}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label-class-name="fake-table-th" align="center" prop="insurance" label="购买产品">
                    <template slot-scope="scope">
                      <div class="product-info">
                        <p class="fake-p" v-for="(item,index) in scope.row.insurance" :key="index">{{item.name}}</p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label-class-name="fake-table-th" align="center" prop="pay_time_date" width="250px"
                    label="购买时间">
                  </el-table-column>
                  <el-table-column label-class-name="fake-table-th" align="center" prop="pay_money" width="200px"
                    label="订单金额">
                  </el-table-column>
                  <el-table-column label-class-name="fake-table-th" align="center" prop="operation_money" width="200px"
                    label="收益金额">
                  </el-table-column>
                  <!-- <el-table-column
                    label-class-name="fake-table-th"
                    align="center"
                    label="操作">
                      <template slot-scope="scope">
                        <div class="control-list">
                          <span class="control-item" :data-data="scope.$index" @click="reviseAccountFun(scope.row)">修改</span>
                          <span class="control-item" :data-data="scope.$index" @click="delAccountFun(scope.row,scope.$index)">删除</span>
                        </div>
                      </template>
                  </el-table-column> -->
                </el-table>
              </div>
              <div class="table-paging">
                <div class="center-place">
                  <span class="sum-number">共{{returnsListObject.resData.total}}条记录</span>
                  <el-pagination background layout="prev, pager, next" :current-page="returnsListObject.formData.page"
                    :page-size="returnsListObject.formData.page_size" :page-count="returnsListObject.resData.page_total"
                    :total="returnsListObject.resData.total" @current-change="returnsListPageChageFun">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 生成二维码-弹窗 -->
    <create-qrcode :visible="createQrcodeObject.visible" :options="createQrcodeObject.options"
      @on-change="function(e) { createQrcodeObject.visible = e.visible; }" />

    <!-- 提现-弹窗 -->
    <withdraw-deposit-dialg :visible="withdrawDepositObject.visible" :options="withdrawDepositObject.options"
      @on-change="WithdrawDepositCallbackFun" />

    <!-- 绑定手机号-弹窗 -->
    <!-- <binding-phone-dialg
      :visible="bindingPhoneObject.visible"
      :options="bindingPhoneObject.options"
      @on-change="bindPhoneCallbackFun"
    /> -->

    <!-- 生成二维码-弹窗 -->
    <binding-phone-qrcode :visible="bindingPhoneObject.visible" :options="bindingPhoneObject.options"
      @on-change="bindPhoneCallbackFun" />

  </div>
</template>

<script>
  import createQrcode from '@/views/insurance/child-pages/account-admin/template/CreateQrcode.vue'
  import withdrawDepositDialg from '@/views/insurance/child-pages/account-admin/template/WithdrawDepositDialg.vue'
  import bindingPhoneDialg from '@/components/binding-phone-dialg/BindingPhoneDialg'
  import bindingPhoneQrcode from '@/views/insurance/child-pages/account-admin/template/BindPhoneQrcode.vue'
  export default {
    // 允许组件模板递归地调用自身
    name: 'account-admin',
    // 声明一组可用于组件实例中的组件
    components: {
      createQrcode,
      withdrawDepositDialg,
      bindingPhoneDialg,
      bindingPhoneQrcode,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        start_end_time: '', // 开始结束时间
        bindingPhoneObject: { // 绑定手机号
          visible: false,
          options: {
            url: ''
          }
        },
        createQrcodeObject: { // 生成二维码
          visible: false,
          options: {
            url: ''
          }
        },
        withdrawDepositObject: { // 提现
          visible: false,
          options: {
            money: ''
          }
        },
        myWalletInfo: { // 我的钱包
          visible: true, // 是否显示面板
          loading: false,
          resData: {},
          fr_list: []
        },
        returnsListObject: { // 收益列表
          loading: false,
          formData: { // 参数
            page: 1, // 否 string	分页码 默认值：1
            page_size: 10, // 否 string	每页显示条数 默认值：10
            type: '1', // 否 string	数据类型【0所有，1我的保费，2推广保费】
            pay_start_time: '', // 否 string	购买开始时间（格式：yyyy-mm-dd hh:ii:ss）
            pay_end_time: '', // 否 string	购买结束时间（格式：yyyy-mm-dd hh:ii:ss）
          },
          resData: { // 响应数据
            page_total: 1, // null, // 数据总页数
            data_total: 1, // null, // 数据总条数
          },
          tableData: {
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取金额数据
        this.getMoneyDataFun();
        // 获取收益列表
        this.getReturnsListFun();
      },
      /**
       * 点击提现按钮
       */
      clickWithdrawDepositBtnFun() {
        let {
          resData
        } = this.myWalletInfo;
        console.log('点击提现按钮 resData.is_wx_bind == ', resData.is_wx_bind);

        if (resData.is_wx_bind) {
          // 打开提现弹窗
          this.openWithdrawDepositWinFun();
        } else {
          // 打开绑定手机号弹窗
          this.openBindingPhoneWinFun()
        }
      },
      /**
       * 打开绑定手机号弹窗
       */
      openBindingPhoneWinFun() {
        let {
          myWalletInfo
        } = this;
        console.log('打开绑定手机号弹窗 myWalletInfo == ', myWalletInfo);
        if (!myWalletInfo.loading) {
          this.myMessage({
            // type: 'success',
            message: '数据加载中，请稍后再试',
          });
          return;
        }

        // 获取绑定手机二维码
        if (!this.bindingPhoneObject.options.url) {
          this.bindingPhoneObject.options.url = this.myWalletInfo.resData.wx_bind_qrcode;
        }
        this.bindingPhoneObject.visible = true;
      },
      /**
       * 绑定手机-回调
       */
      bindPhoneCallbackFun(e) {
        console.log('成功回调 e == ', e);
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        this.bindingPhoneObject.visible = visible;
        // 成功
        if (status == 1) {
          // 打开提现弹窗
          this.openWithdrawDepositWinFun();
        }
        // else { // 其他

        // }
      },
      /**
       * 打开提现弹窗
       */
      openWithdrawDepositWinFun() {
        // let { myWalletInfo } = this;
        // if(!myWalletInfo.loading) {
        //   this.myMessage({
        //     // type: 'success',
        //     message: '数据加载中，请稍后再试',
        //   });
        //   return;
        // }
        this.withdrawDepositObject.visible = true;
        console.log('打开提现弹窗 this.withdrawDepositObject.visible == ', this.withdrawDepositObject.visible);
      },
      /**
       * 提现-回调
       */
      WithdrawDepositCallbackFun(e) {
        console.log('提现-回调 e == ', e);
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        this.bindingPhoneObject.visible = visible;
        this.withdrawDepositObject.visible = visible;
        // 成功
        if (status == 1) {
          // 获取金额数据
          this.getMoneyDataFun();
        }
      },
      /**
       * 生成并打开二维码弹窗
       */
      openCreateQrcodeWinFun() {
        // 生成二维码
        this.createQrcodeFun().then((url) => {
          this.createQrcodeObject.options.url = url;
          this.createQrcodeObject.visible = true;
        });
      },
      /**
       * 生成二维码
       */
      createQrcodeFun() {
        return new Promise((resolve, reject) => {
          this.myRequest({
              url: '/api/insurance/sonaccuntinvitefriend',
            })
            .then((res) => {
              console.log('生成二维码 res == ', res)
              let resData = res.data.data;
              let {
                invite_qr_code
              } = resData;
              // this.createQrcodeObject.options.url = invite_qr_code;
              console.log('生成二维码 this.createQrcodeObject == ', this.createQrcodeObject)
              resolve(invite_qr_code);
            }).catch((err) => {
              console.log('生成二维码 err == ', err)
              reject();
            })
        })
      },
      /**
       * 获取金额数据
       */
      getMoneyDataFun() {
        this.myRequest({
            url: '/api/insurance/sonaccountindex',
          })
          .then((res) => {
            console.log('获取金额数据 res == ', res)
            let resData = res.data.data;
            this.myWalletInfo.resData = resData;
            // 初始化-我的钱包信息
            this.initMyWallerInfoFun(resData.statistical)
            this.myWalletInfo.loading = true;
          }).catch((err) => {
            console.log('获取金额数据 err == ', err)
          })
      },
      /**
       * 初始化-我的钱包信息
       */
      initMyWallerInfoFun(data) {
        let {
          consumption_total, //	string	消费总额
          consumption_earnings_total, //	string	消费收益总额
          promote_total, //	string	推广总额
          promote_earnings_total, //	string	推广收益总额
          all_sales_total, //	string	累计销售总额
          all_earnings_total, //	string	累计收益总额
        } = data;

        let fr_list = [{
          name: '累计金额',
          price: all_sales_total,
        }, {
          name: '消费金额',
          price: consumption_total,
        }, {
          name: '推广金额',
          price: promote_total,
        }, {
          name: '累计收益',
          price: all_earnings_total,
        }, {
          name: '消费收益',
          price: consumption_earnings_total,
        }, {
          name: '推广收益',
          price: promote_earnings_total,
        }];

        this.myWalletInfo.fr_list = fr_list;

        // 钱包-提现弹窗金额-更新
        this.withdrawDepositObject.options.money = this.myWalletInfo.resData.normal_money;
      },
      /**
       * 获取收益列表
       */
      getReturnsListFun() {
        let {
          formData
        } = this.returnsListObject;
        console.log('获取收益列表 参数 == ', formData)
        this.myRequest({
            url: '/api/insurance/sonaccountearningsList',
            data: formData,
          })
          .then((res) => {
            console.log('获取收益列表 res == ', res)
            let resData = res.data.data;
            this.returnsListObject.resData = Object.assign(this.returnsListObject.resData, resData);
            this.returnsListObject.tableData.list = this.returnsListObject.tableData.list.concat(resData.list);
            this.returnsListObject.loading = true;
            console.log('获取收益列表 this.returnsListObject == ', this.returnsListObject)
          }).catch((err) => {
            console.log('获取收益列表 err == ', err)
          })
      },
      /**
       * 初始化-收益列表-当前页
       */
      initReturnsListPagesFun(num = 1) {
        this.returnsListObject.formData.page = num;
      },
      /**
       * 清空-收益列表
       */
      emptyReturnsListPages() {
        this.returnsListObject.tableData.list = [];
      },
      /**
       * 点击按钮-查询-获取收益列表
       */
      clickSerachReturnsListFun() {
        // 初始化-收益列表-当前页
        this.initReturnsListPagesFun();

        // 清空-收益列表
        this.emptyReturnsListPages();

        // 获取收益列表
        this.getReturnsListFun();
      },
      /**
       * 收益列表-查询-类型切换e
       */
      returnsListTypeChangeFun(e, type) {
        if (!type) return;
        console.log('收益列表-查询-类型切换 e == ', e)
        console.log('收益列表-查询-类型切换 type == ', type)
        this.returnsListObject.formData.type = type;

        // 初始化-收益列表-当前页
        this.initReturnsListPagesFun();

        // 清空-收益列表
        this.emptyReturnsListPages();

        // 获取收益列表
        this.getReturnsListFun();
      },
      /**
       * 收益列表-分页切换
       */
      returnsListPageChageFun(e) {
        console.log('收益列表-分页切换 e == ', e)
        this.returnsListObject.formData.page = e;

        // 清空-收益列表
        this.emptyReturnsListPages();

        // 获取收益列表
        this.getReturnsListFun();
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .container {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 0 0 1px;
    background: #f5f5f5;
  }

  .account-admin-module {
    box-sizing: border-box;
    // height: 100%;
    // background: #fff;
    padding: 4px $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;

    .aam-main {
      $module-gutter: 16px;

      // box-sizing: border-box;
      // padding: 24px;
      // border-radius: 4px;
      // background: #fff;
      .aam-module {
        margin-bottom: $module-gutter;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 4px;
        background: #fff;

        &:first-of-type {
          margin-top: 20px;
        }
      }

      .create-qrcode {
        width: auto;

        &::v-deep {
          .fake-btn {
            // width: 100%;
            height: 48px;
            padding: 0;
            font-weight: initial;
            font-size: 16px;
            color: #fff;
            border: 1px solid $common-default-color;
            background: $common-default-color;
            outline: none;

            .icon {
              box-sizing: border-box;
              padding: 0 24px;
              display: flex;
              align-items: center;
              justify-content: center;

              .icon-box {
                margin-right: 12px;

                .iconfont {
                  font-size: 20px;

                  &.icon-weixinzhifu {
                    color: #1aad19;
                  }

                  &.icon-zhifubaozhifu {
                    color: #1477fe;
                  }
                }
              }

              // .icon-text {
              //   text-align: left;
              //   min-width: 50px;
              // }
            }

            &.el-button:hover,
            &.el-button:focus,
            &.el-button:active {
              color: none;
              border-color: none;
              background-color: none;
            }

            // &.el-button:active {
            //   color: $active-default-color;
            //   border-color: $active-default-color;
            // }
          }
        }
      }

      .wallet-module {
        .wm-my-wallet {
          display: flex;

          .wmw-title {
            font-size: 18px;
            color: #222;
          }

          .wmw-rest {
            flex: 1;
            min-width: 0;

            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
            .wmw-deposit {
              // display: flex;
              margin-right: 24px;

              // position: relative;
              // top: -2px;
              .wd-item {
                display: inline-block;
                // display: flex;
                // align-items: flex-end;
                margin-left: 24px;
                font-size: 14px;
                color: #222;

                &.small-hand {
                  color: $common-default-color;
                }

                .big-size {
                  font-weight: 600;
                  font-size: 18px;
                  // position: relative;
                  // top: 2px;
                }
              }
            }
          }

          .wmw-hide {
            box-sizing: border-box;
            padding-top: 4px;
          }

          .small-hand,
          .wmw-hide {
            cursor: pointer;
          }
        }
      }

      .financial-report {
        margin-bottom: $module-gutter;

        .fr-list {
          display: flex;
          flex-wrap: wrap;

          .fl-item {
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            width: 33.3%;
            margin: 0;

            .fl-title {
              font-weight: initial;
              font-size: 14px;
              color: #222;
            }

            .fl-price {
              margin-top: 16px;
              // color: $common-default-color;
              color: #222;

              .price-unit {
                box-sizing: border-box;
                padding-right: 4px;
                font-size: 16px;
              }

              .price-text {
                font-weight: 600;
                font-size: 28px;
              }
            }
          }
        }
      }

      .promote-module {
        .pm-main {
          .pm-header {
            margin: 8px 0 34px;
            display: flex;
            align-items: center;

            .sm-control {
              display: flex;

              // margin: 0 24px;
              .control-item {
                width: 110px;
                margin-right: 32px;

                &::v-deep {
                  .fake-btn {
                    width: 100%;
                    height: 36px;
                    padding: 0;
                    font-size: 16px;
                    color: #787878;
                    border: 1px solid #CCCCCC;
                    background: #fff;
                    outline: none;

                    &.el-button:hover,
                    &.el-button:focus,
                    &.el-button:active {
                      color: none;
                      border-color: none;
                      background-color: none;
                    }

                    &.el-button:active {
                      color: $active-default-color;
                      border-color: $active-default-color;
                      background-color: #fff;
                    }
                  }
                }

                &:first-of-type {
                  margin-left: 0;
                }

                &.reset-btn {
                  &::v-deep {
                    .fake-btn {
                      color: #FFFFFF;
                      border: 1px solid #AAAAAA;
                      background: #AAAAAA;
                    }
                  }
                }

                &.active,
                &.serach-btn {
                  &::v-deep {
                    .fake-btn {
                      color: #FFFFFF;
                      border: 1px solid #E62129;
                      background: #E62129;
                    }
                  }
                }
              }
            }
          }

          .pm-body {
            .table-place {
              margin: 0 0 40px;

              .user-info {
                display: flex;
                align-items: center;

                .user-image {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  margin-right: 10px;
                }

                .user-name {
                  font-size: 14px;
                }
              }

              &::v-deep {
                .el-checkbox__label {
                  display: none;
                }

                .el-checkbox__input.is-checked .el-checkbox__inner,
                .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                  background-color: transparent;
                  border-color: #E62129;
                }

                .el-checkbox__input.is-focus .el-checkbox__inner,
                .el-checkbox__inner::after {
                  border-color: #E62129;
                }

                .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
                  background-color: #E62129;
                }
              }

              &::v-deep {

                // .el-table th.el-table__cell {
                //   background: #F0F0F0;
                // }
                .fake-table-th {
                  padding: 0.2rem 0;
                  /* background: #f3f3f3; */
                  font-weight: 600;
                  font-size: 14px;
                  color: #444343;
                }

                .fake-table-tr {
                  font-size: 16px;
                  color: #666;
                  background: #fff;

                  .el-table__cell:last-of-type {
                    color: $common-default-color;
                  }
                }

                // .el-table--border, .el-table--group {
                //   border: 1px solid #e6e6e6;
                // }
                // .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
                // .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
                //   border: none;
                // }
                // .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
                //   border-top: 1px solid #f0f0f0;
                // }
                // .el-table--border::after, .el-table--group::after, .el-table::before {
                //   display: none;
                // }
                // .el-table, .el-table__expanded-cell,
                // .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
                //   background: transparent;
                // }
                // .el-table .el-table__cell {
                //   height: 66px;
                // }
              }

              .control-list {
                .control-item {
                  margin: 0 8px;
                  display: inline-block;
                  font-size: 16px;
                  color: #E62129;
                  cursor: pointer;
                }
              }
            }

            .table-paging {
              // margin-bottom: 83px;
              display: flex;
              justify-content: center;
              text-align: center;

              .center-place {
                position: relative;

                .sum-number {
                  position: absolute;
                  top: 50%;
                  left: -100%;
                  transform: translateY(-50%);
                  font-size: 16px;
                  color: #787878;
                }
              }
            }
          }
        }
      }
    }

    .form-module {
      margin-right: 32px;

      .fm-itme {
        margin-top: 0;

        &::v-deep {

          .fm-val .field-item .input-box,
          .fm-val .field-item .input-box .el-input__inner,
          .cascader-box .cascader-box .el-input .el-input__inner .el-input,
          .el-input__inner {
            height: 36px;
          }
        }

        &:first-of-type {
          .fm-key {
            min-width: auto;
          }
        }
      }
    }
  }

</style>
