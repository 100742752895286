<template>
  <div class="agreement-container">
    <el-checkbox class="fake-checkbox" v-model="mapInitChecked" @change="agreeCheckedChangeFun">
      <span class="fake-span">
        {{title}}
        <span class="diff-color" @click.stop.prevent="lookingAgreementFun(1)">《用户协议》</span>
        和
        <span class="diff-color" @click.stop.prevent="lookingAgreementFun(2)">《隐私协议》</span>
      </span>
    </el-checkbox>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'agreement-temp',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    title: { // 标题
      type: String,
      default: '登录后代表您阅读并同意佰团乐',
    },
    isChange: { // 是否允许变换
      type: Boolean,
      default: true,
    },
    initChecked: { // 初始值
      type: Boolean,
      default: false,
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      agreement_data: {},// 协议数据
      mapInitChecked: false, // 映射数据
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    initChecked: function(e) {
      console.log('initChecked == ',e)
      this.mapInitChecked = e;
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 是否同意变化
     */
    agreeCheckedChangeFun(e) {
      // 三元运算：判断值是否可以更改
      let result = this.isChange ? e : true
      this.mapInitChecked = result;
      this.$emit('agree-change',{
        'checked': result
      })
    },
    /**
     * 获取协议
     */
    getAgreementFun() {
      this.myRequest({
        method: 'post',
        url: '/api/index/regagreement'
      }).then((res) => {
        console.log('获取协议 res == ',res);
        let data = res.data.data;
        this.agreement_data = data;
      })
    },
    /**
     * 查看协议
     */
    lookingAgreementFun(flag) {
      console.log('查看协议 == ',flag)
      let result = {
        name: '',
        html: '',
      };
      if(flag == 2) { // 隐私政策
        result.name = '隐私政策';
        result.html = this.agreement_data.privacy;
      } else { // 用户协议
        result.name = '用户协议';
        result.html = this.agreement_data.service;
      }

      this.$alert(result.html,  result.name, {
        dangerouslyUseHTMLString: true,
        customClass: 'mesaage-alert-class'
      }).catch((err) => {

      });
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    this.mapInitChecked = this.initChecked;
    // 获取协议
    this.getAgreementFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.agreement-container {
  $checked-color: #36B93F;
  box-sizing: border-box;
  &::v-deep {
    .fake-checkbox {
      .fake-span {
        font-size: 14px;
        color: #333;
        .diff-color {
          color: $checked-color;
        }
      }
      .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color:  $checked-color;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color:  $checked-color;
        border-color:  $checked-color;
      }
    }
  }
}
</style>
