<template>
  <div class="dialog-container">
    <el-dialog
      width="500px"
      top="10vh"
      center
      :visible.sync="mapVisible"
      :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <!-- <div class="dialog-title">
          <h3 class="title-text">推广二维码</h3>
        </div> -->
        <div class="dialog-main">
          <div class="dm-place">
            <qrcode-login changeType="2" @on-change="bindPhoneCallbackFun" />
            <!-- <binding-temp changeType="2" @on-change="bindPhoneCallbackFun" /> -->
          </div>
        </div>
        <p class="dialog-description">{{description}}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qrcodeLogin from '@/views/login/components/qrcode-login';
import bindingTemp from '@/views/login/components/binding-temp.vue';

// 添加人员
export default {
  // 允许组件模板递归地调用自身
  name: 'binding-pone-dialg',
  // 声明一组可用于组件实例中的组件
  components: {
    qrcodeLogin,
    bindingTemp,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '因平台打款是使用微信打款提现前必须绑定微信',
    },
    options: {
      type: Object,
      default: () => {
        let result ={
          url: ''
        }
        return result;
      }
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      loopRequestObject: { // 循环请求对象
        timer: null,
      },
      facility: {}, // 当前设备
      mapVisible: this.visible,
      mapOptions: this.options,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
      if(e) {
        // 监听-循环请求
        this.listenerLoopRequestFun();
      } else {
        // 自定义清除定时器
        this._clearIntervalFun();
      }
    },
    options: function(e) {
      if(this.mapVisible) {
        this.mapOptions = e;
      }
      console.log('options e == ',e)
      console.log('this.mapOptions == ',this.mapOptions)
    },
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      // 自定义清除定时器
      this._clearIntervalFun();
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/失败 3/取消
        message: '取消',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
    /**
     * 绑定手机-回调
     */
    bindPhoneCallbackFun(e) {
      console.log('成功回调 e == ',e);
      let { visible, status, message, data, name, sign } = e;
      let result = {};
      if(status == 1) {
        result = {
          visible: false,
          status: 1, // 1/成功 2/失败 3/取消
          message: '成功',
          data: '',
        };
      }
      // 告知弹窗变化
      this.informChangeFun(result);

      // if(status == 1) { // 确认
      //   // 告知弹窗变化
      //   this.informChangeFun();
      // } else { // 取消
      //   // 告知弹窗变化
      //   this.informChangeFun();
      // }

    },
    /**
     * 监听-循环请求
     */
    listenerLoopRequestFun() {
      const _this = this;
      // 自定义清除定时器
      this._clearIntervalFun();
      // 循环请求
      this.bindPhoenLoopRequestFun({},function(res) {
        // console.log('myLoopRequest == ',res)
        let { timer,data } = res;

        // 获取定时器
        if(!_this.loopRequestObject.timer) {
          _this.loopRequestObject.timer = timer;
        }
        console.log('_this.loopRequestObject.timer a == ',_this.loopRequestObject.timer)

        console.log('是否已绑定 == ',data.is_wx_bind)
        // 是否已绑定
        if(data.is_wx_bind) {
          // 告知结果
          _this.informChangeFun({
            visible: false,
            status: 1, // 1/成功 2/失败 3/取消
            message: '成功',
            data: '',
          });
        } else if( status == 3 ) { // 超时
          // 告知结果
          _this.informChangeFun()
        }
      })
    },
    /**
     * 自定义清除定时器
     */
    _clearIntervalFun(){
      console.log('this.loopRequestObject.timer == ',this.loopRequestObject.timer)
      if(this.loopRequestObject.timer) {
        clearInterval(this.loopRequestObject.timer);
        this.loopRequestObject.timer = null;
      }
    },
    /** 绑定手机-循环请求
     * @author 猫吃鱼
     * @param {Object} opt 参数
     * @param {Function} callback 参数
     */
    bindPhoenLoopRequestFun(opt,callback){
      const _this = this;
      opt = opt || {};
      let default_opt = {
        query: {},
        // time: 6000 / 100, // 定时,毫秒数 默认60秒 6000 / 100 = 60
        // time: -1, // 定时,毫秒数 默认60秒 6000 / 100 = 60
        time: 1000 * 60 * 15, // 定时,毫秒数 默认 15 分钟
        id: '', //	是	string	订单id
        type: '', // 	是	string	类型【vip_order：vip订单，top_order：置顶订单】
      };
      // 如果设置了时间，则换算时间
      if(opt.time) {
        opt.time = opt.time / 1000;
      }
      Object.assign(default_opt,opt);

      console.log('循环请求-参数 == ',default_opt)

      // 声明结果
      let result = {
        timer: null, // 定时器
        status: 0, // 0/正常/执行中 1/成功 2/失败 3/超时
        message: '正常执行中',
        data: {},
      };
      // 定时器
      result.timer = setInterval(function(){
        if(default_opt.time != -1) {
          default_opt.time --;
          console.log('循环请求 time == ',default_opt.time)
          // 请求超时
          if(default_opt.time <= 0) {
            clearInterval(result.timer)
            result.status = 3;
            result.message = '支付超时';
            result.data = {};
            // 回调函数
            if(callback) {
              callback(result)
            }
            return;
          }
        }

        // 发起请求
        _this.myRequest({
          url: '/api/user/userinfostatus',
          data: default_opt.query
        },{is_loading: false}).then((res) => {
          // clearInterval(result.timer)
          console.log('循环请求 res == ',res)
          result.status = 1;
          result.message = '成功';
          result.data = res.data.data;
          // 回调函数
          if(callback) {
            callback(result)
          }
        }).catch((err) => {
          clearInterval(result.timer)
          console.log('循环请求 res == ',err)
          result.status = 2;
          result.message = '失败';
          result.data = err;
          // 回调函数
          if(callback) {
            callback(result)
          }
        })
      },1000);

      // 回调函数
      if(callback) {
        callback(result)
      }

      return result;
    }

  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前
    // 自定义清除定时器
    this._clearIntervalFun();
  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.dialog-container {
  box-sizing: border-box;
  &::v-deep {
    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }
}

.dialog-body {
  .dialog-title {
    // display: flex;
    // align-items: center;
    .title-text {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      color: #444343;
    }
  }
  .dialog-main {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .dm-place {
      &::v-deep {
        .qrcode-login .confirm {
          margin-top: 100px;
          margin-bottom: 100px;
        }
      }
    }
  }
  .dialog-description {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    color: #666;
  }
}
</style>
