<template>
  <div class="dialog-container">
    <el-dialog width="260px" top="10vh" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-title">
          <h3 class="title-text">推广二维码</h3>
        </div>
        <div class="dialog-main">
          <div class="qr-photo">
            <el-image class="fake-img" v-if="canvarConfig.data.imgUrl" :src="canvarConfig.data.imgUrl" fit="scale-down">
            </el-image>
            <!-- <img class="fake-img" v-if="canvarConfig.data.imgUrl" :src="canvarConfig.data.imgUrl" fit="scale-down" /> -->
          </div>
          <!-- <div class="qr-descriptions">
            <span class="title-text">推广二维码</span>
          </div> -->
          <div class="confirm">
            <div class="confirm-item">
              <el-button class="fake-btn" @click="saveCodeFun(canvarConfig.data.imgUrl)">
                <span>保存二维码</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 画布生成图片 -->
    <my-vue-canvas-poster v-if="!canvarConfig.data.imgUrl" :options="{url: mapOptions.url }"
      @on-change="myVueCanvasPosterChangeFun" />
  </div>
</template>

<script>
  import {
    dataURLToBlob, // 将base64转换为blob
    blobToFile, // 将blob转换为file
  } from '@/utils/utils_fun.js';
  import MyVueCanvasPoster from '@/components/my-vue-canvas-poster/MyVueCanvasPoster'
  // 添加人员
  export default {
    // 允许组件模板递归地调用自身
    name: 'create-qrcode',
    // 声明一组可用于组件实例中的组件
    components: {
      MyVueCanvasPoster
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => {
          let result = {
            url: ''
          }
          return result;
        }
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        facility: {}, // 当前设备
        mapVisible: this.visible,
        mapOptions: this.options,
        qrcodeUrl: '', // 二维码图片路径
        canvarConfig: {
          visible: false,
          data: {
            imgUrl: ''
          }
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        this.mapVisible = e;
        // 初始化页面数据
        // this.initPageDataFun2();
      },
      options: function (e) {
        console.log('options e == ', e)
        // if (this.mapVisible) {
        this.mapOptions = e;
        // }
        console.log('this.mapOptions == ', this.mapOptions)
      },
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取设备信息
        this.getFacilityInfo();
      },
      /**
       * 获取设备信息
       */
      getFacilityInfo() {
        let facility = this.myGetCurrentFacility();
        console.log('获取设备信息 facility == ', facility)
        this.facility = facility;
      },
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 3, // 1/成功 2/失败 3/取消
          message: '取消',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 保存二维码
       */
      saveCodeFun(url) {
        // console.log('保存二维码 url == ', url)
        if (!url) return;


        // 校验是否是手机端操作
        let {
          facility
        } = this.facility;
        if (facility && facility == 'mobile') {
          this.myMessage({
            // type: 'success',
            message: '手机端请长按保存图片！',
          });
          return;
        }

        // 如果已经生成过，则使用生成过的
        if (this.qrcodeUrl) {
          // 保存图片到本地
          this.myDownloadIamge(this.qrcodeUrl);
          return;
        }

        // 获取裁剪的图片
        this.getImagesPathFun(url).then((file) => {

          // 获取oss签名
          this.myGetOssSignature(file).then((res) => {
            console.log('oss 签名回调 == ', res);
            let {
              path,
              key,
              policy,
              OSSAccessKeyId,
              accessid,
              signature
            } = res;
            let param = {
              key,
              policy,
              OSSAccessKeyId,
              signature,
            };
            console.log('param == ', param)
            this.myUploadFileToServers(param, file).then((res) => {

              this.qrcodeUrl = path;
              // 保存图片到本地
              this.myDownloadIamge(path);

            });
          })
        });

      },
      /**
       * 画布生成图片-回调函数
       */
      myVueCanvasPosterChangeFun(e) {
        console.log('画布生成图片-回调函数 变化 e ==', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.canvarConfig.visible = visible;
        if (status == 1) {
          this.canvarConfig.data.imgUrl = data;
        } else {

        }
      },
      /**
       * 获取裁剪的图片
       */
      getImagesPathFun(path) {
        return new Promise((resolve, reject) => {

          // 将base64转换为blob
          let blob = dataURLToBlob(path);

          // 将blob转换为file
          let file = blobToFile(blob, path);
          console.log('获取裁剪的图片 file == ', file);

          resolve(file)
        })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .dialog-container {
    box-sizing: border-box;

    &::v-deep {
      .el-dialog__body {
        padding-top: 0;
        padding-bottom: 20px;
      }
    }
  }

  .dialog-body {
    .dialog-title {

      // display: flex;
      // align-items: center;
      .title-text {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #444343;
      }
    }

    .dialog-main {
      .qr-photo {
        margin: 16px auto;
        width: 220px;
        height: 220px;

        .fake-img {
          width: 100%;
          height: 100%;
        }
      }

      .qr-descriptions {
        text-align: center;
        margin-bottom: 24px;

        .title-text {
          font-size: 16px;
          color: #29282e;
        }
      }

      .confirm {
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .confirm-item {
          width: 230px;
          height: 46px;

          // margin: 0 24px;
          .fake-btn {
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 18px;
            color: #fff;
            border-radius: 4px;
            border: 1px solid transparent;
            // background: $common-number1-color;
            background: $common-default-color;
            // background: linear-gradient(#E62129, #F9434B);
            outline: none;

            &.el-button:hover,
            &.el-button:focus,
            &.el-button:active {
              color: none;
              border-color: none;
              background-color: none;
            }

            &.el-button:active {
              color: $active-default-color;
              border-color: $active-default-color;
              background: #fff;
            }

            & span {
              cursor: pointer;
            }

            .iconfont {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

</style>
