<template>
  <div class="dialog-container">
    <el-dialog
      top="10vh"
      center
      :visible.sync="mapVisible"
      :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-title">
          <h3 class="title-text">提现</h3>
        </div>
        <div class="dialog-main">
          <div class="dm-group">
            <div class="dm-cell">
              <div class="form-module">
                <div class="fm-itme">
                  <!-- <div class="fm-key">
                    <div class="key-title required-style">手机号码：</div>
                  </div> -->
                  <div class="fm-val">
                    <div class="field-item">
                      <div class="input-box">
                        <div class="input-unit">￥</div>
                        <el-input v-model="moneyValue" placeholder="请输入金额" @input="inputChangeFun($event,'moneyValue')"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="db-info">
                <p class="fake-p money">
                  可提现金额：<span>￥{{options.money}}</span>
                </p>
                <p class="fake-p"><span class="diff-color" @click.stop="allWithdrawDeposit">全部提现</span></p>
              </div>
              <div class="db-confirm">
                <div class="confirm-item">
                  <el-button class="fake-btn" @click="clickSubmitBtnFun" >
                    <span>提现</span>
                  </el-button>
                </div>
              </div>
            </div>
            <div class="dm-cell">
              <div class="db-tips">
                <h3 class="tips-title">温馨提示：</h3>
                <p class="tips-p">1.提现金额需大于1元</p>
                <p class="tips-p">2.提现到账时间为1-3个工作日</p>
                <p class="tips-p">3.提现到账微信零钱</p>
              </div>
            </div>
          </div>
          <div class="withdraw-record">
            <div class="wr-main aam-module">
              <div class="wr-header">
                <h3 class="wr-title">提现记录</h3>
              </div>
              <div class="wr-body">
                <div class="table-place">
                  <el-table
                    border
                    row-class-name="fake-table-tr"
                    :data="withdrawRecordObject.tableData.list"
                    style="width: 100%;"
                    maxHeight="377px"
                    v-el-table-infinite-scroll="withdrawRecordScrollPageFun"
                  >
                    <!-- <el-table-column
                      label-class-name="fake-table-th"
                      width="100px"
                      align="center"
                      prop="name"
                      label="序号">
                        <template slot-scope="scope">
                          <span v-if="(scope.$index + 1) < 10">00{{scope.$index + 1}} </span>
                          <span v-else-if="(scope.$index + 1) >= 10 || (scope.$index + 1) < 100">0{{scope.$index +1}} </span>
                          <span v-else>{{scope.$index + 1 }} </span>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                      label-class-name="fake-table-th"
                      align="center"
                      prop="add_time"
                      label="时间">
                    </el-table-column>
                    <el-table-column
                      label-class-name="fake-table-th"
                      align="center"
                      prop="money"
                      label="金额">
                    </el-table-column>
                    <el-table-column
                      label-class-name="fake-table-th"
                      align="center"
                      prop="status"
                      label="状态">
                        <template slot-scope="scope">
                          <span v-if="scope.row.status == 0">未打款</span>
                          <span v-else-if="scope.row.status == 1">已打款</span>
                          <span v-else-if="scope.row.status == 2">打款失败</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                      label-class-name="fake-table-th"
                      align="center"
                      label="操作">
                        <template slot-scope="scope">
                          <div class="control-list">
                            <span class="control-item" :data-data="scope.$index" @click="reviseAccountFun(scope.row)">修改</span>
                            <span class="control-item" :data-data="scope.$index" @click="delAccountFun(scope.row,scope.$index)">删除</span>
                          </div>
                        </template>
                    </el-table-column> -->
                  </el-table>
                </div>
                <!-- <div class="table-paging">
                  <div class="center-place">
                    <span class="sum-number">共{{withdrawRecordObject.resData.total}}条记录</span>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :current-page="withdrawRecordObject.formData.page"
                      :page-size="withdrawRecordObject.formData.page_size"
                      :page-count="withdrawRecordObject.resData.page_total"
                      :total="withdrawRecordObject.resData.total"
                      @current-change="withdrawRecordPageChageFun"
                    >
                    </el-pagination>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 添加人员
export default {
  // 允许组件模板递归地调用自身
  name: 'create-qrcode',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        let result ={
          money: ''
        }
        return result;
      }
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapVisible: this.visible,
      mapOptions: this.options,
      moneyValue: '', // 输入金额
      withdrawRecordObject: { // 提现记录
        loading: false,
        noMore: false,
        formData: { // 参数
          page: 1, // 否 string	分页码 默认值：1
          page_size: 10, // 否 string	每页显示条数 默认值：10
        },
        resData: { // 响应数据
          page_total: 1,// null, // 数据总页数
          data_total: 1,// null, // 数据总条数
        },
        tableData: {
          radio: '',
          checked: 0,
          checkList: [],
          list: []
        }
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
      if(e) {
        // 获取页面数据
        this.getPageDataFun();
      }
    },
    options: function(e) {
      if(this.mapVisible) {
        this.mapOptions = e;
      }
    },
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
    * 获取页面数据
    */
    getPageDataFun() {
      // 初始化提现记录参数
      this.initWithdrawRecordFromDataFun();
      // 初始化-输入金额
      this.initMoneyValueFun();
      // 清空-提现记录
      this.emptyWithdrawRecordPages();
      // 获取提现记录
      this.getWithdrawRecordFun();
    },
    /**
     * 初始化提现记录参数
     */
    initWithdrawRecordFromDataFun() {
      let withdrawRecordObject =  { // 提现记录
        loading: false,
        noMore: false,
        formData: { // 参数
          page: 1, // 否 string	分页码 默认值：1
          page_size: 10, // 否 string	每页显示条数 默认值：10
        },
        resData: { // 响应数据
          page_total: 1,// null, // 数据总页数
          data_total: 1,// null, // 数据总条数
        },
        tableData: {
          radio: '',
          checked: 0,
          checkList: [],
          list: []
        }
      };
      this.withdrawRecordObject = withdrawRecordObject;
    },
    /**
     * 初始化-输入金额
     */
    initMoneyValueFun() {
      this.moneyValue = '';
    },
    /**
     * 输入框监听变化
     */
    inputChangeFun(event,name) {
      console.log(event)
      console.log(name)
      console.log(this[name])
    },
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/失败 3/取消
        message: '取消',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
    /**
     * 获取提现记录
     */
    getWithdrawRecordFun() {
      let { formData } = this.withdrawRecordObject;
      console.log('获取提现记录 参数 == ',formData)
      this.myRequest({
        url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=cash&pluginsaction=index',
        data: formData,
      })
      .then((res) => {
        console.log('获取提现记录 res == ',res)
        let resData = res.data.data;
        this.withdrawRecordObject.resData = Object.assign(this.withdrawRecordObject.resData, resData);
        this.withdrawRecordObject.tableData.list = this.withdrawRecordObject.tableData.list.concat(resData.list);
        this.withdrawRecordObject.loading = true;
        console.log('获取提现记录 this.withdrawRecordObject == ',this.withdrawRecordObject)
      }).catch((err) => {
        console.log('获取提现记录 err == ',err)
      })
    },
    /**
     * 清空-提现记录
     */
    emptyWithdrawRecordPages() {
      this.withdrawRecordObject.tableData.list = [];
    },
    /**
     * 提现记录-分页切换
     */
    withdrawRecordPageChageFun(e) {
      console.log('提现记录-分页切换 e == ',e)
      this.withdrawRecordObject.formData.page = e;
      // 清空-提现记录
      this.emptyWithdrawRecordPages();
      // 获取提现记录
      this.getWithdrawRecordFun();
    },
    /**
     * 提现记录-滚动分页
     */
    withdrawRecordScrollPageFun(e) {
      console.log('提现记录-滚动分页 e == ',e)
      let { tableData, resData } = this.withdrawRecordObject;
      if(tableData.list.length >= resData.total) return;

      this.withdrawRecordObject.formData.page += 1;
      // 清空-提现记录
      // this.emptyWithdrawRecordPages();
      // 获取提现记录
      this.getWithdrawRecordFun();
    },
    /**
     * 全部提现
     */
    allWithdrawDeposit() {
      let { money } = this.options
      this.moneyValue = money;
    },
    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {
      console.log('点击提交按钮')

      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 提现申请
      this.applicationForWithdrawalFun().then(() => {
        this.myMessage({
          duration: 3000,
          type: 'success',
          message: '提现成功!',
        });
        // 初始化-输入金额
        this.initMoneyValueFun();
        // 告知弹窗变化
        this.informChangeFun({
          visible: true,
          status: 1, // 1/成功 2/失败 3/取消
          message: '更新',
          data: '',
        });
        // 提现记录-分页切换-第一页
        this.withdrawRecordPageChageFun(1);
      });
    },
    /**
     * 提现申请
     */
    applicationForWithdrawalFun() {
      return new Promise((resolve,reject) => {
        this.myRequest({
          url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=cash&pluginsaction=create',
          data: {
            money: this.moneyValue, //	是	string	提现金额
            // accounts_type: '', //	是	string	提现帐户类型 2微信 3支付宝 （已废弃）
            // accounts_qr: '', //	是	string	收款二维码图片（已废弃）
          }
        })
        .then((res) => {
          console.log('提现申请 res == ',res)
          resolve();
        }).catch((err) => {
          console.log('提现申请 err == ',err)
          reject();
        })
      })
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let { moneyValue } = this;
      let { money } = this.options;

      // 金额
      if(!moneyValue || moneyValue == 0) {
        this.myMessage({
          message: '请输入有效的提现金额!'
        });
        return false;
      } else if(!this.REGEXP.is_floating.test(moneyValue)) {
        this.myMessage({
          message: '请输入正确的格式，可保留小数点后两位!'
        });
        return false;
      } else if(moneyValue > money) {
        this.myMessage({
          message: '提现金额不能大于有效金额!'
        });
        return false;
      } else if(moneyValue < 1) {
        this.myMessage({
          message: '提现金额不能小于1!'
        });
        return false;
      }

      return true;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.dialog-container {
  box-sizing: border-box;
  &::v-deep {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.dialog-body {
  .dialog-title {
    // display: flex;
    // align-items: center;
    .title-text {
      // text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #444343;
    }
  }
  .dialog-main {
    .dm-group {
      display: flex;
      .dm-cell {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: 64px;
        &:first-of-type {
          align-items: initial;
          margin-left: 0;
          &::before {
            display: none;
          }
        }
        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 140px;
          background: #d4d4d4;
          position: absolute;
          left: 0;
          top: 55%;
          transform: translateY(-50%);
        }
      }
    }
    .db-info {
      margin: 20px 0 14px;
      display: flex;
      .fake-p {
        font-size: 14px;
        color: #808080;
        &.money {
          margin-right: 10px;
          flex: 1;
          min-width: 0;
        }
        .diff-color {
          color: $common-default-color;
        }
      }
    }
    .db-confirm {
      // margin: 30px auto;
      display: flex;
      justify-content: center;
      .confirm-item {
        width: 100%;
        height: 46px;
        // margin: 0 24px;
        .fake-btn {
          width: 100%;
          height: 100%;
          font-weight: 500;
          font-size: 14px;
          color: #fff;
          border-radius: 4px;
          border: 1px solid transparent;
          // background: $common-number1-color;
          background: $common-default-color;
          outline: none;
          &.el-button:hover,&.el-button:focus,&.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
          &.el-button:active {
            color: $active-default-color;
            border-color: $active-default-color;
            background: #fff;
          }
          & span {
            cursor: pointer;
          }
          .iconfont {
            margin-right: 6px;
          }
        }
      }
    }
    .db-tips {
      .tips-title {
        margin: 20px 0 10px;
        font-weight: 500;
        font-size: 16px;
        color: #444343;
      }
      .tips-p {
        margin-top: 6px;
        font-size: 14px;
        color: #666;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  .withdraw-record {
    .wr-main {
      .wr-header {
        margin: 30px 0 16px;
        .wr-title {
          font-weight: initial;
          font-size: 18px;
          color: #222;
        }
      }
      .wr-body {
        .table-place {
          margin: 0 0 40px;
          &::v-deep {
            // .el-table th.el-table__cell {
            //   background: #F0F0F0;
            // }
            .fake-table-th {
              padding: 0.2rem 0;
              /* background: #f3f3f3; */
              font-weight: 600;
              font-size: 14px;
              color: #444343;
            }
            .fake-table-tr {
              font-size: 16px;
              color: #666;
              background: #fff;
            }
            // .el-table--border, .el-table--group {
            //   border: 1px solid #e6e6e6;
            // }
            // .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
            // .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
            //   border: none;
            // }
            // .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
            //   border-top: 1px solid #f0f0f0;
            // }
            // .el-table--border::after, .el-table--group::after, .el-table::before {
            //   display: none;
            // }
            // .el-table, .el-table__expanded-cell,
            // .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            //   background: transparent;
            // }
            // .el-table .el-table__cell {
            //   height: 66px;
            // }
          }
          .control-list {
            .control-item {
              margin: 0 8px;
              display: inline-block;
              font-size: 16px;
              color: #E62129;
              cursor: pointer;
            }
          }
        }
        .table-paging {
          // margin-bottom: 83px;
          display: flex;
          justify-content: center;
          text-align: center;
          .center-place {
            position: relative;
            .sum-number {
              position: absolute;
              top: 50%;
              left: -100%;
              transform: translateY(-50%);
              font-size: 16px;
              color: #787878;
            }
          }
        }
      }
    }
  }
  .form-module {
    .fm-itme {
      margin-top: 26px;
      &::v-deep {
        .fm-val .field-item .input-box {
          max-width: initial;
        }
        .fm-val .field-item .input-box .input-unit,
        .fm-val .field-item .input-box,
        .fm-val .field-item .input-box .el-input,
        .fm-val .field-item .input-box .el-input__inner,
        .cascader-box .cascader-box .el-input .el-input__inner
        .el-input,
        .el-input__inner {
          height: 60px;
          font-weight: 600;
          font-size: 20px;
        }
        .el-input__inner {
          padding: 0;
        }
        .fm-val .field-item .input-box .input-unit {
          line-height: 61px;
          font-weight: 400;
          font-size: 18px;
          color: #444343;
        }
      }
      &:first-of-type {
        .fm-key {
          min-width: auto;
        }
      }
    }
  }
}
</style>
